import React, { useContext, useEffect, useState } from 'react';
import { SpotifyApiContext } from './SpotifyApi';
import useLocalStorageState from './useLocalStorageState';
import { loadCollection } from './api/collection';
import { Collection, Album } from './types';

interface CollectionDetailProps {
    collectionId: string;
}

const CollectionDetail: React.FC<CollectionDetailProps> = ({
    collectionId
}) => {
    const { client } = useContext(SpotifyApiContext);
    const [collection, setCollection] = useLocalStorageState<Collection | null>(`collection-${collectionId}`, null);
    const [playingAlbum, setPlayingAlbum] = useState('')

    useEffect(() => {
        if (!collection) {
            loadCollection(client!, collectionId).then(setCollection);
        }
    }, [collection, client, setCollection, collectionId]);

    if (!collection) {
        return <h1>loading</h1>;
    }

    const handleAlbumClick = async (album: Album) => {
        if (client) {
            await Promise.all([
                client.setShuffle({ state: false }),
                client.setRepeat({ state: 'off' }),
                client.play({ uris: album.trackUris}),
            ]);
            setPlayingAlbum(album.id);
        }
    };

    return (
        <div>
            <div className="content">
                <h1>{collection.name}</h1>
            </div>
            <div className="collection-container">
                {collection.albums.map(album => (
                    <div
                        className={`collection-item ${album.id === playingAlbum ? 'playing' : ''}`}
                        key={album.id}
                        onClick={() => handleAlbumClick(album)}
                    >
                        {album.coverImgUrl
                            ? <img src={album.coverImgUrl} className="album-art" />
                            : <div className="album-art" />}
                        <div className="album-metadata">
                            <span className="album-name">{album.name}</span>
                            &nbsp;‒&nbsp;
                            <span className="album-artist">{album.artist}</span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default CollectionDetail;
