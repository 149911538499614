import React, { useContext, useEffect } from 'react';
import useLocalStorageState from './useLocalStorageState';
import { SpotifyApiContext } from './SpotifyApi';
import { loadAllCollectionPlaylists } from './api/playlists';

interface CollectionListProps {
    onCollectionSelected: (collectionId: string) => void;
}

const CollectionList: React.FC<CollectionListProps> = ({
    onCollectionSelected
}) => {
    const { client } = useContext(SpotifyApiContext);
    const [collections, setCollections] = useLocalStorageState(
        'collections',
        [] as SpotifyApi.ListOfCurrentUsersPlaylistsResponse['items'],
    );

    useEffect(() => {
        if (collections.length === 0) {
            loadAllCollectionPlaylists(client!).then(setCollections);
        }
    }, [collections.length, client, setCollections]);

    return (
        <div>
            <div className="content">
                <h1>Your collections</h1>
            </div>
            <div className="collection-container">
                {collections.map(collection => (
                    <div
                        className="collection-item"
                        key={collection.id}
                        onClick={() => onCollectionSelected(collection.id)}
                    >
                        {collection.images.length
                            ? <img src={collection.images[0].url} className="album-art" />
                            : <div className="album-art" />}
                        <div className="album-metadata">
                            <span className="album-name">{collection.name.replace(/Collection: /, '')}</span>
                        </div>
                    </div>
                ))}
            </div>
        </div> 
    );
}

export default CollectionList;
