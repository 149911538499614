import React from 'react';
import DeviceSelectorContainer from './DeviceSelectorContainer';
import useLocalStorageState from './useLocalStorageState';
import CollectionList from './CollectionList';
import CollectionDetail from './CollectionDetail';
import PlayPauseButton from './PlayPauseButton';

const AuthApp: React.FC = () => {
    const [selectedCollection, setSelectedCollection] = useLocalStorageState('selectedCollection', '');

    return (
        <div className="app">
            {selectedCollection ? <div className="top-bar">
                <span onClick={() => setSelectedCollection('')}>Back</span>
            </div> : <div className="top-bar-spacer" />}
            <div className="flex-grow">
                {selectedCollection
                    ? <CollectionDetail collectionId={selectedCollection} />
                    : <CollectionList onCollectionSelected={setSelectedCollection} />}
            </div>
            <div className="bottom-bar">
                <PlayPauseButton />
                <div className="flex-grow" />
                <DeviceSelectorContainer />
            </div>
        </div>
    );
}

export default AuthApp;
