import React, { useContext, useState, useEffect } from 'react';
import { SpotifyApiContext } from './SpotifyApi';

interface DeviceSelectorProps {
    selectedDeviceId?: string;
    onSelectDevice: (deviceId: string) => void;
}

const DeviceSelector: React.FC<DeviceSelectorProps> = ({
    selectedDeviceId,
    onSelectDevice,
}) => {
    const { client } = useContext(SpotifyApiContext);
    const [devices, setDevices] = useState<SpotifyApi.UserDevicesResponse['devices']>([]);

    useEffect(() => {
        if (client) {
            client.getMyDevices().then(data => setDevices(data.body.devices));
        }
    }, [client]);

    const activeDevice = devices.find(device => device.id === selectedDeviceId);
    const otherDevices = devices.filter(device => device.id !== selectedDeviceId);
    const allDevices = otherDevices.concat(activeDevice ? [activeDevice] : []);
    
    return (
        <ul className={`device-selector ${activeDevice ? 'has-selected' : ''}`}>
            {allDevices.map(device => (
                <li
                    className={device.id === selectedDeviceId ? 'selected' : ''}
                    onClick={() => onSelectDevice(device.id!)}
                    key={device.id!}
                >
                    {device.name}
                </li>
            ))}
        </ul>
    );
};

export default DeviceSelector;
