import React from 'react';

interface LogInScreenProps {
    url: string;
}

const LogInScreen: React.FC<LogInScreenProps> = ({ url }) => {
    return (
        <div className="LogInScreen">
            <a href={url}>Log in</a>
        </div>
    );
}

export default LogInScreen;
