import React, { useContext, useState, useEffect } from 'react';
import { SpotifyApiContext } from './SpotifyApi';

interface PlayPauseButtonProps {}

const PlayPauseButton: React.FC<PlayPauseButtonProps> = () => {
    const { client } = useContext(SpotifyApiContext);
    const [playing, setIsPlaying] = useState(false);
    
    useEffect(() => {
        if (client) {
            client.getMyCurrentPlaybackState().then(resp => setIsPlaying(resp.body && resp.body.is_playing));
        }
    }, [client]);

    const handleClick = async () => {
        try {
            if (client && playing) {
                await client.pause();
                setIsPlaying(false);
            } else if (client && !playing) {
                await client.play();
                setIsPlaying(true);
            }
        } catch (err) {
            alert(`Error changing play state: ${err.message}`);
        }
    }
    
    return (
        <span
            className="play-button"
            onClick={handleClick}
        >{playing ? '⏸' : '▶️'}</span>
    );
};

export default PlayPauseButton;
