import React, { useContext } from 'react';
import { SpotifyApiContext } from './SpotifyApi';
import DeviceSelector from './DeviceSelector';
import useLocalStorageState from './useLocalStorageState';

const DeviceSelectorContainer: React.FC = () => {
    const { client } = useContext(SpotifyApiContext);
    const [selectedDeviceId, setSelectedDeviceId] = useLocalStorageState('device', '');

    const handleSelectDevice = async (deviceId: string) => {
        setSelectedDeviceId(deviceId);
        try {
            // @ts-ignore type is wrong
            await client!.transferMyPlayback({ deviceIds: [deviceId] })
        } catch (err) {
            alert(`Could not transfer playback: ${err.message}`);
        }
    };

    return (
        <DeviceSelector
            selectedDeviceId={selectedDeviceId}
            onSelectDevice={handleSelectDevice}
        />
    );
}

export default DeviceSelectorContainer;
