import { useState } from "react";

function useLocalStorageState<T>(storageKey: string, defaultValue: (() => T) | T): [T, (x: T) => void] {
    const [value, setStateValue] = useState(() => {
        try {
            return JSON.parse(localStorage.getItem(storageKey) || '');
        } catch (err) {
            return typeof defaultValue === 'function' ? (defaultValue as () => T)() : defaultValue;
        }
    });
    
    return [value, (newValue: T) => {
        try {
            localStorage.setItem(storageKey, JSON.stringify(newValue));
        } catch (err) {}
        setStateValue(newValue);
    }];
};

export default useLocalStorageState;
