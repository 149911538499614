import React, { useState, useEffect } from 'react';
import SpotifyWebApi from 'spotify-web-api-node';

interface SpotifyApiProviderProps {
    accessToken?: string;
}

interface TSpotifyApiContext {
    isLoggedIn: boolean;
    client?: SpotifyWebApi;
    user?: SpotifyApi.UserProfileResponse;
}

const Context = React.createContext<TSpotifyApiContext>({
    isLoggedIn: false,
    client: undefined,
});

export const getAuthorizeUrl = (clientId: string) => {
    const { hostname, protocol, port } = window.location;
    const here = `${protocol}//${hostname}${port ? `:${port}` : ''}`;
    return `https://accounts.spotify.com/authorize?client_id=${clientId}&response_type=token&redirect_uri=${here}&scope=app-remote-control,streaming,playlist-modify-private,playlist-read-private,user-modify-playback-state,user-read-playback-state`
}

export const SpotifyApiProvider: React.FC<SpotifyApiProviderProps> = ({
    children,
    accessToken,
}) => {
    const [currentUser, setCurrentUser] = useState<TSpotifyApiContext['user']>();
    const [client] = useState(() => new SpotifyWebApi());

    useEffect(() => {
        async function loadUser() {
            client.setAccessToken(accessToken!);
            try {
                const user = await client.getMe();
                setCurrentUser(user.body);
            } catch (err) {
                console.error('Error loading user', err);
            }
        }

        if (accessToken) {
            loadUser();
        }
    }, [accessToken, client]);

    return (
        <Context.Provider value={{ isLoggedIn: !!currentUser, client, user: currentUser }}>
            {children}
        </Context.Provider>
    );
}

export const SpotifyApiContext = Context;

export const SpotifyApiConsumer = Context.Consumer;
