import SpotifyWebApi from 'spotify-web-api-node';
import { loadAllTracksInPlaylist } from './playlists';
import { Collection } from '../types';

export const loadCollection = async (client: SpotifyWebApi, id: string): Promise<Collection> => {
    const { body: playlist } = await client.getPlaylist(id);
    const tracks = await loadAllTracksInPlaylist(client, id);
    const rawAlbums: {
        album: SpotifyApi.PlaylistTrackObject['track']['album'],
        artist: string,
    }[] = Object.values(
        tracks.reduce((acc, { track }) => ({
            ...acc,
            [track.album.id]: {
                album: track.album,
                artist: track.artists.map(artist => artist.name).join(', '),
            }
        }), {}));

    const albums = await Promise.all(rawAlbums.map(async ({ album, artist }) => ({
        coverImgUrl: album.images.length > 0 ? album.images[0].url : undefined,
        id: album.id,
        uri: album.uri,
        name: album.name,
        artist,
        year: '1234',
        trackUris: (await client.getAlbumTracks(album.id)).body.items.map(item => item.uri),
    })));

    return {
        name: playlist.name.replace(/Collection: /, ''),
        albums,
    };
};