import SpotifyWebApi from 'spotify-web-api-node';

export async function loadAllCollectionPlaylists(client: SpotifyWebApi) {
    let totalPlaylists: number = -1;
    const playlists: SpotifyApi.ListOfCurrentUsersPlaylistsResponse['items'] = [];

    while (totalPlaylists === -1 || playlists.length < totalPlaylists) {
        const resp = await client!.getUserPlaylists({ limit: 50, offset: playlists.length });
        totalPlaylists = resp.body.total;
        playlists.push(...resp.body.items);
    }
    
    return playlists
        .filter(playlist => playlist.name.startsWith('Collection:'));
}

export async function loadAllTracksInPlaylist(client: SpotifyWebApi, id: string) {
    let totalTracks: number = -1;
    const tracks: SpotifyApi.PlaylistTrackResponse['items'] = [];
    
    while (totalTracks === -1 || tracks.length < totalTracks) {
        const resp = await client.getPlaylistTracks(id, { limit: 50, offset: tracks.length });
        totalTracks = resp.body.total;
        tracks.push(...resp.body.items);
    }

    return tracks;
}