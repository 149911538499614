import React from 'react';
import './App.css';
import { SpotifyApiProvider, getAuthorizeUrl, SpotifyApiConsumer } from './SpotifyApi';
import AuthApp from './AuthApp'
import useLocalStorageState from './useLocalStorageState'
import LogInScreen from './LogInScreen'

const authorizeUrl = getAuthorizeUrl('805e647acd9146fe8a73d5be229e6c8a');

const App: React.FC = () => {
    const urlAccessToken = window.location.hash.match(/#access_token=(.+?)$/);
    const [accessToken, setAccessToken] = useLocalStorageState('accessToken', '');

    if (urlAccessToken) {
        setAccessToken(urlAccessToken[1]);
        window.location.hash = '';
        window.location.reload();
    }

    return (
        <SpotifyApiProvider accessToken={accessToken}>
            <SpotifyApiConsumer>
                {({ isLoggedIn }) => isLoggedIn
                    ? <AuthApp />
                    : <LogInScreen url={authorizeUrl} />}
            </SpotifyApiConsumer>
        </SpotifyApiProvider>
    );
}

export default App;
